
  import { defineComponent, inject, onMounted, ref} from "vue";
  import { useRouter, useRoute } from "vue-router";
  import Modal from "@/components/UI/Modal";
  import Toast from "@/components/UI/Toast";
  import axios from "@/api/axios";
  import $ from "jquery";
  import qs from "qs";
  export default defineComponent({
    name: "VoteTest",
    components: {
    },
    setup() {
      // const model = ref(false)
      const model = ref([false, false, false])
      const disModel = ref(false)
      const txtArea = ref('')
      // const type = ref([])
      const router = useRouter();
      const route = useRoute();
      //  展示用户信息
      const userInfo = inject("userInfo") as any;
      const commentList = ref([
        {
          title: '服务态度',
          content: [false, false ,false, false, false]
        },
        {
          title: '菜品味道',
          content: [false, false ,false, false, false]
        },
        {
          title: '环境卫生',
          content: [false, false ,false, false, false]
        }
      ])

      const comment = ref([false, false ,false, false, false])

      onMounted(() => {
        $('.home-wapper').css('padding-top', '0')
        if (!(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
          window.location.href = 'https://www.bncc.com/User/CanteenVoting.html'
        }
      })

      function handleChange(event: any, index: any) {
        model.value[index] = !model.value[index]
      }


      function viewStatic() {
        const token = localStorage.getItem("token");
        if (!token) {
          Modal({
            title: "温馨提示",
            content: "登录后才可以进行投票！",
            onClose: () => {
              router.push({
                name: "Login",
                query: {
                  redirect: router.currentRoute.value.fullPath,
                },
              });
            },
          });
          return false;
        } else {
          router.push({
            name: "VoteView"
          });
        }
      }

      function save() {
        let flag = false
        let type1: any = 0
        let type2: any = 0
        let type3: any = 0
        commentList.value[0].content.forEach((item: any) => {
          if (item) {
            type1 +=1
          }
        })

        commentList.value[1].content.forEach((item: any) => {
          if (item) {
            type2 +=1
          }
        })

        commentList.value[2].content.forEach((item: any) => {
          if (item) {
            type3 +=1
          }
        })


        commentList.value.forEach((item) => {
          if ((item.content as any).indexOf(1) == -1) {
            flag = true
          }
        })
        
        if (flag) {
          Toast({
            type: "error",
            title: '请必须对每一项都进行评价！',
          });
          return false
        }
        const data = qs.stringify({
          remark: txtArea.value,
          type1: type1,
          type2: type2,
          type3: type3,
        });

        const token = localStorage.getItem("token");
        if (!token) {
          Modal({
            title: "温馨提示",
            content: "登录后才可以进行投票！",
            onClose: () => {
              router.push({
                name: "Login",
                query: {
                  redirect: router.currentRoute.value.fullPath,
                },
              });
            },
          });
          return false;
        } else {
          axios
            .post("/M/UserServer/canteenVoting", data, {
              params: {
                validate: true,
              },
            })
            .then((res) => {
              if (res.data.success) {
                Toast({
                  type: "success",
                  title: res.data.msg,
                  onClose: () => {
                    router.push({
                      name: "VoteView"
                    });
                    txtArea.value = ''
                  },
                });

              } else {
                Toast({
                  type: "error",
                  title: res.data.msg,
                });
              }
            })
            .catch((err) => {
              console.log(err)
            });
        }
      }

      function evaClick(index: any, idx: any) {
        (commentList.value[index] as any).content = [false, false, false, false, false]
        for(let i=0;i<=idx;i++) {
          (commentList.value[index] as any).content[i] = i+1
        }
        
      }

      return {
        userInfo,
        model,
        disModel,
        handleChange,
        // disHandleChange,
        txtArea,
        save,
        viewStatic,
        comment,
        evaClick,
        commentList
      }
    },
  });
